/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "assets/scss/fonts";

@include mat.core();
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "@ng-select/ng-select/themes/default.theme.css";

html {
  --bs-body-line-height: unset !important;
}

body {
  background-color: #f2f2f2;
  font-family: "NotoSans Regular", sans-serif;
  font-size: 18px;

  h1 {
    font-family: "NotoSans Bold", Helvetica, Arial, sans-serif;
    font-size: 35px;
  }
}

.bold {
  font-family: "NotoSans Bold", Helvetica, Arial, sans-serif;
}

.italic {
  font-style: italic;
}

.light {
  font-family: "NotoSans Regular", sans-serif;
}

.icon-style {
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.icon-disabled {
  opacity: 0.5;
  cursor: default !important;
}

input, select, ng-select {
  background-color: white !important;
  border-radius: 0;
  border: var(--bs-border-width) solid black;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

input:disabled {
  background-color: #f9f9f9 !important;
}

input {
  font-size: 18px;
  padding: 0.12rem 0.375rem;
}

input[type="radio"] {
  height: 16px;
  width: 16px;
  margin-top: 8px;
}

label {
  padding-top: 4px;
}

select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.transparent-background {
  background-color: transparent !important;
}

.step-title {
  background-color: #B30000 !important;
  color: #fff;
  font-size: 18px;
  width: fit-content;
  padding: 4px 10px 4px 10px;
}

.step-title-gray {
  background-color: #9c9e9f !important;
  color: #fff;
  font-size: 18px;
  width: fit-content;
  padding: 4px 10px 4px 10px;
}

.step-number {
  font-size: 48px;
  color: transparent;
  -webkit-text-stroke: 2px #B30000; /* Width and color of the outline */
}

.full-step-number {
  font-size: 48px;
  color: transparent;
  -webkit-text-stroke: unset;
  color: #B30000;
}

.step-number-gray {
  font-size: 48px;
  color: transparent;
  -webkit-text-stroke: 2px #9c9e9f; /* Width and color of the outline */
}

.form-item-title {
  color: #B30000 !important;
  font-size: 18px;
  font-family: "NotoSans Bold", Helvetica, Arial, sans-serif;
}

.small-icon-button {
  border-radius: 15px !important;
  padding: 5px !important;
  border-color: #B30000 !important;
  background-color: #B30000 !important;
  color: white !important;
}

.border-top-divider {
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.red {
  color: #B30000 !important;
}

.form-gap {
  gap: 3rem;
}

select:disabled {
  opacity: 0.4;
}

.ng-value {
  position: relative;
  z-index: 5;
}

.ng-select {
  input {
    background-color: transparent !important;
  }

  .ng-select-container {
    border-radius: 0;
    border: unset;
    min-height: 31px;
  }

  .ng-dropdown-panel {
    border-radius: 0;
    border: var(--bs-border-width) solid black;
    box-shadow: unset;
    left: -1px;
    width: calc(100% + 2px);
  }

  .ng-arrow {
    border-top-color: #000 !important;
  }

  .ng-clear-wrapper {
    color: #000 !important;
  }

  .ng-value-label {
    font-size: 18px;
  }

  .ng-option-label {
    font-size: 18px;
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 31px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #000;
}

.ng-valid {
  color: black !important;
  border-color: black !important;
}

.neo-btn-success {
  background-color: #07A163 !important;
  padding-left: 25px;
  padding-right: 25px;
  color: white !important;
  font-size: 18px !important;
  border-radius: 0;
}

.btn.neo-btn-success.disabled {
  border-color: #07A163 !important;
}

.btn-back {
  background-color: #AAAAAA !important;
  color: white !important;
  width: 158px;
  font-size: 18px !important;
  border-radius: 0;
}

.btn.btn-back.disabled {
  border-color: #AAAAAA !important;
}

//input.ng-invalid.ng-dirty, ng-select.ng-invalid.ng-dirty, ng-select.ng-invalid.ng-dirty .ng-select-container, select.ng-invalid.ng-dirty {
//  color: #dc3545 !important;
//  border-color: #dc3545 !important;
//}

input.ng-invalid, ng-select.ng-invalid, ng-select.ng-invalid .ng-select-container, select.ng-invalid {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

input[type="radio"]:checked {
  accent-color: #07A163;
}

.text-center {
  text-align: center;
}

.small-input {
  width: 80px;
}

.very-small-input {
  width: 50px;
}

.no-border {
  border-width: unset !important;
  border-style: unset !important;
}

td {
  vertical-align: middle;
}

table > :not(caption) > * > * {
  padding: 0.25rem !important;
}

table > :not(caption) > * > *:first-child {
  padding-left: unset !important;
}

table > :not(caption) > * > *:last-child {
  padding-right: unset !important;
}

.hr-top {
  color: #707070;
  opacity: 1;
  margin: 24px 0;
}

.hr-bottom {
  color: #707070;
  opacity: 1;
  margin: 0 0 36px 0;
}

.hr-middle {
  color: #707070 !important;
  opacity: 1 !important;
  margin: unset !important;
}

.form-line {
  height: 31px;
  align-items: center;
  display: flex !important;
  gap: 0.25rem !important;
}

.w-48 {
  width: 48% !important;
}

.pointer {
  cursor: pointer !important;
}

.cursor {
  cursor: default !important;
}

.custom-checkbox-label {
  margin: 6px 2.5px 3px 2.5px;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  background: url('assets/images/uncheck_icon.svg') no-repeat;
  background-size: contain;
  display: inline-block;
  cursor: pointer;
}

/* Hide the default checkbox */
.custom-checkbox {
  display: none;
}

/* Change the background image when checkbox is checked */
.custom-checkbox:checked + .custom-checkbox-label {
  margin-top: 3.5px;
  margin-bottom: 0.5px;
  margin-left: unset;
  margin-right: unset;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
  background: url('assets/images/check_icon.svg') no-repeat;
  background-size: contain;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 0;
}

.error-box {
  margin-top: 3rem;
  padding: 1rem;
  opacity: 1;
  background-color: #dc3545;
  color: white;
}

.full-width-input {
  width: 100%;
  max-width: 368px;
  height: 68px;

  @include media-breakpoint-up(xl) {
    width: 398px;
  }
}

.half-width-input {
  max-width: 158px;
  width: 100%;

  @include media-breakpoint-up(xl) {
    width: 158px;
  }
}

.two-columns {
  width: 100%;
  @include media-breakpoint-up(xl) {
    flex-wrap: wrap;
    height: 215px;
    width: 860px;
  }
}

.table-max-width {
  @include media-breakpoint-up(md) {
    max-width: 370px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.bold-link {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}
