@font-face {
  font-family: 'NotoSans Bold';
  src: url('/assets/fonts/NotoSans-Bold.ttf') ;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSans Medium';
  src: url('/assets/fonts/NotoSans-Medium.ttf') ;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSans Regular';
  src: url('/assets/fonts/NotoSans-Regular.ttf') ;
  font-style: normal;
}
